@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css');

.PESQUISADO::before{
    font-family:'FontAwesome';
    content: '\f005';
    font-size: 30px;
    color: #2b7ce9;
}
.ESTRANGEIRO::before{
    font-family:'FontAwesome';
    content: '\f007';
    font-size: 30px;
    color: #2b7ce9;
}
.PESSOA_FISICA::before{
    font-family:'FontAwesome';
    content: '\f007';
    font-size: 30px;
    color: #2b7ce9;
}
.PESSOA_FISICA_RELACIONADA::before{
    font-family:'FontAwesome';
    content: '\f007';
    font-size: 30px;
    color: #2b7ce9;
}
.NULA::before{
    font-family:'FontAwesome';
    content: '\f1ad';
    font-size: 30px;
    color: grey;
}
.ATIVA::before{
    font-family:'FontAwesome';
    content: '\f1ad';
    font-size: 30px;
    color: violet;
}
.SUSPENSA:before{
    font-family:'FontAwesome';
    content: '\f1ad';
    font-size: 30px;
    color: red;

}
.INAPTA::before{
    font-family:'FontAwesome';
    content: '\f1ad';
    font-size: 30px;
    color: green;
}
.BAIXADA::before{
    font-family:'FontAwesome';
    content: '\f1ad';
    font-size: 30px;
    color: black;
}
.FIP::before{
    font-family:'FontAwesome';
    content: '\f1ad';
    font-size: 30px;
    color: brown;
}
.GESTOR::before{
    font-family:'FontAwesome';
    content: '\f1ad';
    font-size: 30px;
    color: orange;
}
